/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800");
@import "../node_modules/@fortawesome/fontawesome-pro/css/all.min.css";
@import "../node_modules/@fortawesome/fontawesome-pro/css/sharp-light.min.css";

body{
    margin:0;
}

.gs-page {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
}

.gs-content-container {
    width: 90%;
    max-width: var(--gs-max-width);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gs-form-field {
    width: 100%;
}

.custom-input {
    border: unset;
    border: 0.063rem solid var(--gs-border-color);
    border-radius: 0.188rem;
    height: 2.188rem;
    width: 100%;
    box-sizing: border-box;
}

.gs-inline-error {
    font: italic normal 600 0.75rem/0.938rem Poppins;
    color: #D12A2A;
    margin-left: -0.938rem;
    margin-bottom: 1rem;

}

.gs-input-error,
.custom-input.gs-input-error{
    border-color:#D12A2A;
    border-width: 0.063rem;
}

.gs-api-error-link {
    color: #FA4600;
    font-family: 'Poppins', sans-serif;
    font-weight: 415;
    font-size: 0.8rem;
}

.headline {
    margin-bottom: 1rem;
    text-align: center;
}

.headline-container {
    background: #F2F2F2;
    padding: 2rem 0 2.5rem;
    color: #4D4D4D;
    font: normal normal normal 24px/31px Montserrat;
    margin-bottom: 1.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: 1px solid #B4B4B4;
}

.headline-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: var(--gs-max-width);
}

.currenyCustom {
    top: 6px;
    position: relative;
}

/* wallet style */
.method-img i {
    font-size: 1.5rem;
}
/* wallet style */

@media only screen and (min-width: 576px) {
    .headline-content h1 {
        font-size: 2rem;
    }

    .currenyCustom {
        top: -3px;
        position: relative;
        font: normal normal bold 52px / 63px Montserrat;
    }
}

@media only screen and (min-width: 992px) {
    .gs-content-container {
        justify-content: space-between;
    }

    .headline-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 90%;
    }

    .headline {
        margin-bottom: 1rem;
        text-align: left;
    }
}

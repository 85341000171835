@use '@angular/material' as mat;
@use "sass:map";

@include mat.core();

/* region palettes */
$gs-orange: (
    50 : #fee9e0,
    100 : #fec8b3,
    200 : #fda380,
    300 : #fc7e4d,
    400 : #fb6226,
    500 : #fa4600, // this is main one
    600 : #f93f00,
    700 : #f93700,
    800 : #f82f00,
    900 : #f62000,
    A100 : #ffffff,
    A200 : #ffebea,
    A400 : #ffbcb7,
    A700 : #ffa59d,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$gs-warn: (
    50 : #fde0e0,
    100 : #f9b3b3,
    200 : #f58080,
    300 : #f14d4d,
    400 : #ee2626,
    500 : #eb0000,
    600 : #e90000,
    700 : #e50000,
    800 : #e20000,
    900 : #dd0000,
    A100 : #ffffff,
    A200 : #ffd1d1,
    A400 : #ff9e9e,
    A700 : #ff8585,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$grey: (
    50 : #f2f2f2,
    100 : #e0e0e0,
    200 : #cbcbcb,
    300 : #b6b6b6,
    400 : #a6a6a6,
    500 : #969696,
    600 : #8e8e8e,
    700 : #838383,
    800 : #797979,
    900 : #686868,
    A100 : #fce5e5,
    A200 : #f7b7b7,
    A400 : #ff7c7c,
    A700 : #ff6363,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$calendar-grey: (
    50 : #fcfcfc,
    100 : #f7f7f7,
    200 : #f2f2f2,
    300 : #ebebeb,
    400 : #c9c9c9,
    500 : #ababab,
    600 : #818181,
    700 : #6d6d6d,
    800 : #4d4d4d,
    900 : #2b2b2b,
  contrast: ( 50:#fcfcfc,
    100 : #f7f7f7,
    200 : #f2f2f2,
    300 : #ebebeb,
    400 : #c9c9c9,
    500 : #ababab,
    600 : #818181,
    700 : #6d6d6d,
    800: #4d4d4d,
    900 : #2b2b2b
    )
);


$my-primary: mat.define-palette($gs-orange, 500);
$my-accent: mat.define-palette($grey, A200, A100, A400);
// The warn" palette is optional and defaults to red if not specified.
$my-warn: mat.define-palette($gs-warn, 500);
$gs-grey-palette: mat.define-palette($grey, 500);
$gs-calendar-grey: mat.define-palette($calendar-grey, 600);

/* endregion palettes*/

/* region: Typography */
$montserrat-typography-level: mat.define-typography-level(
    $font-family: Montserrat,
    $font-weight: 400,
    $font-size: 1rem,
    $line-height: 1,
    $letter-spacing: normal,
);
$poppins-typography-level: mat.define-typography-level(
    $font-family: Poppins,
    $font-weight: 400,
    $font-size: 1rem,
    $line-height: 1,
    $letter-spacing: normal,
);
$poppins-typography-config: mat.define-typography-config(
    $font-family: Poppins,
);
    // $headline-1: $montserrat-typography-level,
    // $headline-2: $montserrat-typography-level,
    // $headline-3: $montserrat-typography-level,
    // $headline-4: $montserrat-typography-level,
    // $headline-5: $montserrat-typography-level,
    // $headline-6: $montserrat-typography-level,
    // $subtitle-1: $montserrat-typography-level,
    // $subtitle-2: $montserrat-typography-level,
    // $body-1: $poppins-typography-level,
    // $body-2: $poppins-typography-level,
    // $caption: $poppins-typography-level,
    // $button: $poppins-typography-level

$gs-button-typography-config: mat.define-typography-config(
    $button: mat.define-typography-level(
        $font-family: 'Poppins',
        $font-size: var(--acs-font-size),
        $font-weight: var(--acs-font-weight)
    )
);


// @include mat.button-typography($gs-button-typography-config);
/* endregion: Typography */

$gs-button-theme: mat.define-light-theme(
    (
        color: (
            primary: $my-primary,
            accent: $my-accent,
            warn: $my-warn,
        ),
        typography: $gs-button-typography-config,
        density: 0,
    )
);

$gs-input-theme: mat.define-light-theme(
    (
        color: (
            primary: $gs-grey-palette,
            accent: $my-accent,
            warn: $my-warn,
        ),
        typography: $poppins-typography-config,
        density: 0,
    )
);

$gs-datepicker-theme: mat.define-light-theme(
    (
        color: (
            primary: $gs-calendar-grey,
            accent: $my-accent,
            warn: $my-warn,
        ),
        typography: $poppins-typography-config,
        density: 0,
    )
);

$gs-radio-theme: mat.define-light-theme(
    (
        color: (
            primary: $my-primary,
            accent: $my-accent,
            warn: $my-warn
        ),
        typography: $poppins-typography-config,
        density: 0
    )
);

// Emit theme-dependent styles for common features used across multiple components.
// @include mat.core-theme($my-theme);

// Emit styles for MatButton based on `$my-theme`. Because the configuration
// passed to `define-light-theme` omits typography, `button-theme` will not
// emit any typography styles.
@include mat.button-theme($gs-button-theme);
@include mat.datepicker-theme($gs-datepicker-theme);

@include mat.form-field-theme($gs-input-theme);
@include mat.expansion-theme($gs-input-theme);
@include mat.card-theme($gs-input-theme);
@include mat.divider-theme($gs-input-theme);
@include mat.select-theme($gs-input-theme);
@include mat.option-theme($gs-input-theme);
@include mat.radio-theme($gs-radio-theme);
@include mat.checkbox-theme($gs-radio-theme);
@include mat.tooltip-theme($gs-radio-theme);


// @include mat.all-component-themes($my-theme);


/* region Global Styles */
:root {
    --gs-orange: #fa4600;
    --gs-hover: #C64A1F;
    --gs-grey: #969696;
    --gs-border-color: #CCCCCC;
    --acs-font-weight: 700;
    --acs-font-size: 1.188rem;
    --gs-font-color: #4D4D4D;
    --gs-error-color: #EB0000;
    --gs-tooltip-icon-color: #D33400;
    --gs-max-width: 1400px;
}

p {
    font-family: Poppins;
    color: var(--gs-font-color);
}

a {
    color: var(--gs-orange) !important;
    font-size: var(--acs-font-size) !important;
    font-weight: var(--acs-font-weight) !important;
    font-family: Poppins;
}

a:hover,
a:focus {
    color: var(--gs-hover) !important;
    cursor: pointer;
}

button:focus {
    outline: 4px solid var(--gs-orange);
}

/* region headings */
h1 {
    font: normal normal 800 1.5rem/2rem Montserrat;
    color: var(--gs-font-color);
}

h2 {
    font: normal normal 800 1.125rem/1.5rem Montserrat;
    color: var(--gs-font-color);
}

h3 {
    font: normal normal bold 18px/22px Montserrat;
    color: var(--gs-font-color);
}
/* endregion headings*/

.feature:hover::after {
    content: '';
    position: absolute;
    height: 8px;
    bottom: 0;
    width: 100%;
    left: 0px;
    background-image: linear-gradient(to left, #f34383, #e95524 51%, #fdb713);
}
/*  endregion Global Styles */

.gs-dialog{
    width: 500px;
}

.gs-radio-group {
    display: flex;
    flex-direction: column;
}

.gs-radio-button {
    margin: 0.5rem 0;
}

.gs-radio-button label {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 1rem;
    width: 100%;
    color: var(--gs-font-color);
}

.gs-radio-button .mdc-form-field {
    width: 100%;
}

button.button-link {
    color: var(--gs-orange);
    font: italic normal 600 16px/18px Poppins;
    letter-spacing: 0px;
    opacity: 1;
    cursor: pointer;
    border: none;
    background-color: #FFFFFF;
}

.mdc-list-item__primary-text {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

button.gs-form-button-primary {
    background-color: #FA4600;
    color: white;
    border-radius: 1.625rem;
    width: fit-content;
    padding: .5rem 1.5rem;
    margin-top: 0;
    font-family: 'Poppins', sans-serif;
    height: fit-content;
}

button.gs-form-button-primary:active {
    background-color: #FA4600;
    color: white;
    border: none;
}

button.gs-form-button-primary:hover {
    background-color: #C64A1F;
    color: #FFFFFF;
    border: .188rem solid #C64A1F;
}

button.gs-form-button-primary:focus {
    background-color: #FFFFFF;
    color: #FA4600;
    border: 0.188rem solid #FA4600;
}

button.gs-form-button-secondary {
    background-color: #FFFFFF;
    color: #FA4600;
    border-radius: 1.625rem;
    padding: 1rem 1.25rem;
    margin-top: 0;
    font-family: 'Poppins', sans-serif;
}

button.gs-form-button-secondary:active {
    background-color: #FFFFFF;
    color: #C64A1F;
    border: none;
}

button.gs-form-button-secondary:hover {
    color: #FFFFFF;
    border: .188rem solid #C64A1F;
}

button.gs-form-button-secondary:focus {
    background-color: #FFFFFF;
    color: #C64A1F;
    border: 0.188rem solid #C64A1F;
}

.gs-post-notification {
    color: var(--gs-font-color);
    font: italic normal 500 1rem / 1.5rem Poppins;
    text-align: left;
    width: fit-content;
}

.gs-post-notification-error {
    font: italic normal 600 1rem / 1.125rem Poppins;
    color: #EB0000;
    text-align: left;
}

.gs-error {
    font-family: Poppins;
    font-size: .75rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: .4px;
}

.gs-tooltip-icon {
    color: var(--gs-tooltip-icon-color);
    margin-left: 0.25rem;
}

.gs-tooltip-icon:focus,
.gs-tooltip-icon:focus-visible {
    outline: 1px solid var(--gs-tooltip-icon-color);
}

// Custom modified datepicker classes for gs dates.
button:not(.gs-custom-date-class) .mat-calendar-body-selected {
    color: white;
    border-radius: 4px;
    font-size: var(--acs-font-size);
    font-weight: var(--acs-font-weight);
}

button.gs-custom-date-class .mat-calendar-body-cell-content {
    color: white;
    font-size: var(--acs-font-size);
    font-weight: var(--acs-font-weight);
}

button.mat-calendar-body-active:focus {
    outline: none;
}

button[aria-pressed="true"].gs-custom-date-class {
    outline: 3px solid grey;
    outline-offset: 1px;
}

button.gs-custom-date-class span.mat-calendar-body-selected {
    background-color: var(--gs-orange);
}

.mat-datepicker-content .mat-calendar-next-button:not(.mat-mdc-button-disabled),
.mat-datepicker-content .mat-calendar-previous-button:not(.mat-mdc-button-disabled) {
    color: rgba(0, 0, 0, 0.87) !important;
}

.mat-datepicker-content .mat-calendar-next-button.mat-mdc-button-disabled,
.mat-datepicker-content .mat-calendar-previous-button.mat-mdc-button-disabled {
    color: rgba(0, 0, 0, 0.38) !important;
}

/* region NEW STYLESGUIDE */
@media only screen and (min-width: 992px) {
    h1.h1-lg {
        font-size: 46px !important;
        font-weight: 700;
    }
}

/* endregion NEW STYLESGUIDE */
